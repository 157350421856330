<template lang="pug">
NuxtLink(:to="`/minha-conta/box/${order.id}`")
  .order
    .order-header
      h3 {{ title }}
      .order-status(v-html="status")

    span.link Ver detalhes

</template>
<script setup lang="ts">
import type { PropType } from 'vue'
import type { SubscriptionBoxOrder } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

import { subscriptionBoxOrderTitle } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

const props = defineProps({
  order: {
    type: Object as PropType<SubscriptionBoxOrder>,
    required: true
  }
})

const title = computed(() => subscriptionBoxOrderTitle(props.order))

const status = computed(() => {
  switch(props.order.status) {
    case "CANCELED": return 'Essa box foi <span class="magenta-text">cancelada</span>'
    case "NEW": return 'Essa box acaba de ser <span class="magenta-text">encomendada</span>'
    case "IN_PRODUCTION": return 'Essa box está <span class="magenta-text">em produção</span>'
    case "PRODUCT_SELECTION_FAILED": return 'Essa box está <span class="magenta-text">em produção</span>'
    case "PRODUCTS_SELECTED": return 'Essa box está <span class="magenta-text">em produção</span>'
    case "READY_TO_POST": return 'Essa box está <span class="magenta-text">pronta para envio</span>'
    case "IN_TRANSIT": return 'Essa box já foi <span class="magenta-text">enviada</span>'
    case "AWAITING_CUSTOMER_PICKUP": return '<span class="magenta-text">Disponível para coleta</span>  na agência mais próxima'
    case "DELIVERED": return 'Essa box já foi <span class="magenta-text">entregue</span>'
    case "RETURNED": return 'Essa box foi <span class="magenta-text">retornada</span> pela transportadora'
    case "MISPLACED": return 'Essa box foi <span class="magenta-text">extraviada</span>'
    case "SUSPECTED_FRAUD": return 'Essa box está <span class="magenta-text">em análise</span>'
  }
})
</script>
<style lang="sass" scoped>
.order
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: left
  padding: 16px
  min-width: 240px
  height: 140px
  background-color: #FFECDF
  color: #000 !important
  border-radius: 8px

  h3
    margin: 0
    color: #000
    font-size: 24px

  .link
    font-weight: bold
    color: #EE3D8A
</style>