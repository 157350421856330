<template lang="pug">
.magazines(ref="root")
  h2 Revista Magenta

  PaginatedList(
    v-if="filteredMagazines.length > 0",
    name="magazines",
    :items="filteredMagazines",
    :itemsPerPage="3",
    :breakpoints="{ 387: { itemsPerPage: 4 }, 575: { itemsPerPage: 6 }, 763: { itemsPerPage: 8 }, 951: { itemsPerPage: 10 }}",
    v-slot="{ items: magazines }"
  )
    .magazine-list
      Magazine(v-for="magazine in magazines", :order="magazine")

  .no-magazines(v-else)
    | Hmm... você ainda não possui nenhuma revista disponível.
</template>
<script setup lang="ts">
import type { PropType } from 'vue'
import type { SubscriptionBoxOrder } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

import Magazine from './Magazine.vue'

const props = defineProps({
  orders: {
    type: Object as PropType<SubscriptionBoxOrder[]>,
  }
})

const filteredMagazines = computed(() => {
  if (!props.orders) {
    return [];
  }

  return props.orders
    .filter(order =>
      order.edition >= "202104" &&
      order.status != "FRAUD" &&
      order.status != "SUSPECTED_FRAUD" &&
      order.status != "CANCELED")
    .sort((a, b) => a.id < b.id ? 1 : -1)
})
</script>
<style lang="sass" scoped>
.magazines
  margin-bottom: 32px

  .magazine-list
    display: grid
    grid-gap: 32px
    grid-template-columns: repeat(auto-fill, minmax(156px, 1fr))

  .no-magazines
    width: 100%
    text-align: center
    font-size: 18px
    color: #AAA

  .actions
    margin-top: 16px

    a.action
      font-weight: bold
      padding: 16px
      cursor: pointer
      color: #EE3D8A
</style>
