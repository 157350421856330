<template lang="pug">
a(:href="magazineUrl", target="_blank")
  .magazine
    img(:src="coverUrl", width="298", height="421")
    span {{ title }}
</template>
<script setup lang="ts">
import type { PropType } from 'vue'
import type { SubscriptionBoxOrder } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

import { subscriptionBoxOrderTitle } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'

const props = defineProps({
  order: {
    type: Object as PropType<SubscriptionBoxOrder>,
    required: true
  }
})

const title = computed(() => subscriptionBoxOrderTitle(props.order));
const coverUrl = computed(() => `https://static.boxmagenta.com.br/magazine/cover/${props.order.edition}-cover.jpg`)
const magazineUrl = computed(() => `https://boxmagenta.com.br/revista/#/${props.order.edition}/${props.order.id}`)
</script>
<style lang="sass" scoped>
.magazine
  display: flex
  flex-direction: column
  align-items: center

  img
    border-radius: 4px
    width: 100%
    max-width: 240px
    height: auto

  span
    font-weight: bold
    margin-top: 12px
</style>