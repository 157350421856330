<template lang="pug">
.orders(ref="root")
  h2 Suas boxes

  PaginatedList(
    v-if="filteredOrders.length > 0",
    name="subscription-box-orders",
    :items="filteredOrders",
    :itemsPerPage="4",
    :breakpoints="{ 539: { itemsPerPage: 6 }, 795: { itemsPerPage: 9 } }",
    v-slot="{ items: orders }"
  )
    .order-list
      SubscriptionBoxOrderItem(v-for="order in orders", :key="order.id" :order="order")

  .no-orders(v-else)
    | Hmm... você ainda não possui nenhuma box.
</template>
<script setup lang="ts">
import type { PropType } from 'vue'
import type { SubscriptionBoxOrder } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'
import SubscriptionBoxOrderItem from './SubscriptionBoxOrderItem.vue'

const props = defineProps({
  orders: {
    type: Object as PropType<SubscriptionBoxOrder[]>,
  }
})

const filteredOrders = computed(() => {
  if (!props.orders) {
    return []
  }

  return props.orders
    .filter(order => order.status != "FRAUD")
    .sort((a, b) => a.id < b.id ? 1 : -1)
})
</script>
<style lang="sass" scoped>
.orders
  margin-bottom: 32px

  .order-list
    display: grid
    grid-gap: 16px
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr))

  .no-orders
    width: 100%
    text-align: center
    font-size: 18px
    color: #AAA
</style>
